import type { ProviderGetImage } from '@nuxt/image';

// @ts-expect-error: @nuxt/image bug, should be fixed in the next version
import { createOperationsGenerator } from '#image';
import { joinURL } from 'ufo';

const operationsGenerator = createOperationsGenerator();

const envs = [
  {
    host: 'https://artcurial.com/item-images',
    identifier: 'prod',
    subdomain: '',
  },
  {
    host: 'https://dev.artcurial-maya.com/item-images',
    identifier: 'dev',
    subdomain: 'dev.',
  },
  {
    host: 'https://preprod.artcurial-maya.com/item-images',
    identifier: 'preprod',
    subdomain: 'preprod.',
  },
];

export const getImage: ProviderGetImage = (src, { modifiers = {} } = {}) => {
  const operations = operationsGenerator(modifiers);
  const { googleBucketInventoryDownloadUrl, googleBucketInventoryUrl } =
    useRuntimeConfig().public;

  let s = src;
  for (const { host, identifier } of envs) {
    const imageRenderer = host;
    const inventoryUrl = googleBucketInventoryUrl.replace('ENV', identifier);
    const bucketDownloadUrl = googleBucketInventoryDownloadUrl.replace(
      'ENV',
      identifier
    );

    s =
      s
        .replace(inventoryUrl, imageRenderer)
        .replace(bucketDownloadUrl, imageRenderer)
        .replace(/\?.*$/, '') + '?force_webp=true';
  }

  return {
    url: joinURL(s + (operations ? '&' + operations : '')),
  };
};
